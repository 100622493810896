import Signup from './views/Signup';
import Verification from './views/Verification';
import Recovery from './views/Recovery';
import RecoveryVerification from './views/RecoveryVerification';

import { Route, Routes, Link } from 'react-router-dom';

function App() {

  return (
    <div >

      <header >
        <nav class="navbar navbar-expand-lg bg-primary" data-bs-theme="dark">
          <div class="container-fluid">
            <Link class="navbar-brand" to="https://3vidence.com">3vidence </Link>
          </div>
        </nav>

      </header>

      <Routes>
        <Route path="/" element={<Signup />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/verification/:otp"
          loader={({ params }) => {
            console.log(params.otp);
          }}
          action={({ params }) => { }}
          element={<Verification />}
        />
        <Route path="/recovery" element={<Recovery />} />
        <Route path="/recovery/:otp"
          loader={({ params }) => {
            console.log(params.otp);
          }}
          action={({ params }) => { }}
          element={<RecoveryVerification />}
        />
      </Routes>

    </div>
  );
}

export default App;
