import { useState } from "react";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function Signup() {
  const [email, setEmail] = useState('');
  const [records, setRecords] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsFetching(true);

    try {
      const response = await fetch(process.env.REACT_APP_BACKEND + '/auth/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      toast.success('User Created', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      })

      const data = await response.json();
      setRecords(data);

    } catch (error) {
      toast.error('User Already exists', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        })
      console.error('There was a problem with your fetch operation:', error);
    } finally {
      setIsFetching(false);
    }
  };

  if (isFetching) {
    return <div>Processing...</div>;
  }

  if (records) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-3"></div>
          <div className="col">
            <h1 className="h2">Signup</h1>
            <em class="mb-1">Please check your email to confirm your account</em><br />
            {/* <em class="mb-1">Password: {records.password}</em><br />
            <Link to={"/verification/" + records.otp} class="btn btn-link" target="_blank">
              Verification
            </Link> */}
          </div>
          <div className="col-4"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-3"></div>
        <div className="col">
          <h1 className="h2">Signup</h1>
          <form onSubmit={handleSubmit}>
            <input className="form-control" type="email" value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email" required />
            <br />
            <button type="submit" className="btn btn-primary">Send</button>
          </form>
          <ToastContainer />
        </div>
        <div className="col-4"></div>
      </div>
    </div>
  );
}

export default Signup;
